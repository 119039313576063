<template>
  <div class="flex justify-center flex-col mb-10">
    <h1
      class="md:text-5xl animate-fade-in-down2 text-3xl self-center font-bold dark:text-white"
    >
      Hello,
    </h1>
    <h1
      class="md:text-5xl animate-fade-in-down text-3xl self-center font-bold dark:text-white"
    >
      My name is Albin Sander.
    </h1>
    <h1
      class="md:text-5xl animate-fade-in-down text-3xl self-center font-bold dark:text-white mt-12 pl-8"
    >
      I'm a Frontend developer based in Sweden.
    </h1>
  </div>
</template>

<script>
export default {
  name: "Name",
};
</script>

<style></style>
